
// Minimum JSON fetch API
// https://developers.google.com/web/updates/2015/03/introduction-to-fetch
// TODO: Missing polyfill? - https://github.com/github/fetch
// Note: There is no request cancellation for fetch - https://github.com/whatwg/fetch/issues/20

const Fetch = {
	call: async function(url, opts = {}) {
		opts = Object.assign({ credentials: 'include' }, opts);
		return fetch(url, opts).then(async function(response) {
			if (response.status !== 200) {
				console.error(`Request to ${url} responded with status: ${response.status}`);
				return null;
			}
			return await response.json();
		}).catch(err => {
			console.error(`Request to ${url} responded with: ${err}`);
			return null;
		});
	},
	post: function(url, data, method = 'POST') {
		return this.call(url, {
			method: method,
			body: JSON.stringify(data),
			headers: {"Content-type": "application/json; charset=UTF-8"}
		})
	},
	get: function(url, params) {
		if (typeof params === 'object') {
			for (let i in params) {
				params[i] = encodeURIComponent(params[i]);
			}
			let urlsp = new URLSearchParams(params);
			url = `${url}?${urlsp.toString()}`;
		}
		return this.call(url, { method: 'GET' });
	},
	delete: function(url) { return this.call(url, { method: 'DELETE' }) },
	patch: function(url, data) { return this.post(url, data, 'PATCH') },
	put: function(url, data) { return this.post(url, data, 'PUT') }
};

const Get = Fetch.get;
const Post = Fetch.post;
const Patch = Fetch.patch;
const Put = Fetch.put;
const Delete = Fetch.delete;

export { Get, Post, Patch, Delete, Put };

export default Fetch;
