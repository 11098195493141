/**
 * @prettier
 * @flow
 */

import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Chart from 'liana-ui/legacy/components/chart/Chart';
import {
	Button,
	Grid,
	Segment,
	Divider,
	Checkbox,
	Dropdown,
	ItemGroup,
	CardGroup,
	ChartInfo,
	EmptyState,
	Message,
	ActionHeader
} from 'liana-ui/components/';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';
import type { Props as ItemProps } from 'liana-ui/components/item/Item';
import type { Props as CardProps } from 'liana-ui/components/card/Card';
import type { Props as ActionHeaderProps } from 'liana-ui/components/header/ActionHeader';

// prettier-ignore
type Props = {
	/** 
	 	An action items list must have items or cards. 
	 	PROPS[ItemProps=/components/items/items/item/, CardProps=/components/cards/card/]
	*/
	items: Array<ItemProps> | Array<CardProps>,
	/**
		An action items list may have an action header with various buttons and filters.
		PROPS[ActionHeaderProps=/components/containers/action-header/]
	*/
	actionHeader?: ActionHeaderProps,
	/** An paginated action items list must be provided with the total amount of possible rows, without paging. Folder rows included. */
	totalItems?: number,
	/** An item actions can have different layouts. */
	layout?: 'small' | 'big',
		/** 
	  	An item can limit header to maximum amount of lines with ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    An item can limit description to maximum amount of lines with ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/**
		An action items list can have a chart and chart info.
		PROPS[ChartProps=/components/statistics/charts/, ChartInfoProps=/components/statistics/charts/filter]
	*/
	chart?: {
		chart: ChartProps,
		chartInfo: ChartInfoProps
	} | React.Node,
	/**
		Represents column count per row in card Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns?: string | number,
	/** A card group can have its columns stack on-top of each other after reaching mobile (boolean) or tablet ('tablet') breakpoints. */
	stackable?: boolean | 'tablet',
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number,
	/** 
	 	An action items list can display different components. 
	 	PROPS[ButtonProps=/components/buttons/button/]
	*/
	display?: {
		type: string,
		buttons: Array<ButtonProps>
	},
	/** 
	 	An action items list can allow a user to sort items. 
	 	PROPS[DropdownProps=/components/dropdown/dropdown/]
	*/
	sort?: DropdownProps,
	/** An action items list can allow a user to select items. */
	select?: {
		enableSelectAll: boolean,
		selectedItems: Array<string>,
		onSelect: (
			event: SyntheticEvent<>,
			data: {
				name: string,
				value: string | number,
				checked: boolean,
				selected: Array<string>
			}
		) => void
	},
	/** An action items can allow to paginate rows. */
	paginate?: {
		currentPage: number,
		paginateAmount: number,
		loadingMore: boolean,
		onLoadMore: (
			event: SyntheticEvent<>,
			data: {
				currentPage: number
			}
		) => void
	},
	/** An action items list must have an empty state. PROPS[...EmptyStateProps=/components/feedback/empty-state/empty-state]  */
	emptyState: {
		displayEmptyState: boolean,
		...EmptyStateProps
	},
	/** An action table can override some default translation keys. */
	translations?: {
		totalItemsLabel: string,
		noResults: string
	},
	/** An action items list should show an animation (fly away) on deleted items. Must match the item IDs of the rows to be animated also by type. */
	deletedItems?: Array<string>,
	/** Callback on when an delete animation (fly away) is complete. Must match the item IDs of the rows to be animated also by type. Call API to remove the item from database and and re-render list. Remember also to clear deletedItems in this callback. */
	onAfterDeleteItems?: () => void
};

const DEFAULTS = {
	loading: false,
	display: {
		type: 'items'
	},
	emptyState: {
		displayEmptyState: false,
		image: `${process.env.baseUrl}img/empty-states/empty-default.png`,
		header: <FormattedMessage id='component.action-items.emptyStateHeader' />,
		content: <FormattedMessage id='component.action-items.emptyStateContent' />
	},
	translations: {
		totalItemsLabel: 'component.action-items.totalItemsLabel',
		noResults: 'component.action-items.noResults'
	}
};

let selectedItems = [];

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/item/#types-items */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and states
	let [stickyActive, setStickyActive] = useState();
	let [stickyHeight, setStickyHeight] = useState();
	let [randomItemsID] = useState(`actionitems-${Date.now()}-${Math.round(Math.random() * 9999)}`);

	// Merge translations
	let translations = Object.assign({}, DEFAULTS.translations, props.translations);

	// Merge empty state
	let emptyState = Object.assign({}, DEFAULTS.emptyState, props.emptyState);

	// Internal variables and states
	selectedItems = props.select && props.select.selectedItems ? props.select.selectedItems : [];

	const handleLoadMore = (event) => {
		// Trigger onLoadMore callback funtion
		if (typeof props.paginate.onLoadMore === 'function') {
			props.paginate.onLoadMore(event, {
				currentPage: props.paginate.currentPage + 1
			});
		}
	};

	const handleSelect = (event, data) => {
		if (data.value === 'all') {
			selectedItems = data.checked ? props.items.map((row) => row.id) : [];
		} else if (data.value === 'clear') {
			selectedItems = [];
		} else if (data.checked) {
			selectedItems = [...selectedItems, ...[data.value]];
		} else {
			selectedItems.splice(selectedItems.indexOf(data.value), 1);
		}

		// Trigger onSelect callback funtion
		if (typeof props.select.onSelect === 'function') {
			props.select.onSelect(event, { ...data, selected: selectedItems });
		}
	};

	const getHeaderLabels = (event) => {
		let labels = [],
			deepItems = props.totalItems;

		if (props.limit?.limit) {
			// Use props.translations.totalItemsLabel for limit label if props.limit.tranlations.amount not provided
			if (!props.limit.translations?.amount) {
				props.limit = { ...props.limit, translations: { amount: translations.totalItemsLabel } };
			}
			labels.push({ ...props.limit, amount: deepItems });
		} else {
			labels.push({
				text: <FormattedMessage id={translations.totalItemsLabel} values={{ amount: deepItems }} />
			});
		}

		if (props.actionHeader?.header?.labels) {
			labels = Array.isArray(props.actionHeader?.header?.labels)
				? [...labels, ...props.actionHeader?.header?.labels]
				: [...labels, ...[props.actionHeader?.header?.labels]];
		}

		let clearLabel = {};
		if (props.select) {
			clearLabel = {
				visible: selectedItems.length > 0,
				text: (
					<>
						<FormattedMessage id='component.action-items.clear' /> ({selectedItems.length})
					</>
				),
				circular: true,
				color: 'purple',
				icon: 'fa-remove',
				onClick: (event) => handleSelect(event, { value: 'clear' })
			};
			labels.push(clearLabel);
		}

		return labels;
	};

	const formatItems = (items) => {
		let formatted = [];
		items.map((item, index) => {
			let selected =
					props.select && Array.isArray(props.select.selectedItems)
						? selectedItems.indexOf(item.id) !== -1
							? true
							: false
						: undefined,
				deleted = Array.isArray(props.deletedItems) && props.deletedItems.indexOf(item.id) !== -1;

			formatted.push({
				...item,
				selected: selected,
				deleted: deleted,
				onAfterDeleteItems: props.onAfterDeleteItems
			});
		});
		return formatted;
	};

	// Assign classes
	const itemsWrapperClasses = classNames('actionitems-list-wrapper', {
		'has-header': props.actionHeader || !isNaN(props.totalRows)
	});

	let totalItems = props.totalItems,
		currentPage = props.paginate ? props.paginate.currentPage : undefined,
		paginateAmount = props.paginate ? props.paginate.paginateAmount : undefined,
		nextMin = currentPage * paginateAmount,
		max = (currentPage + 1) * paginateAmount,
		nextMax = max > totalItems ? totalItems : max,
		showMore = totalItems > nextMin;

	// Define ActionItems
	let actionItems = props.emptyState.displayEmptyState ? (
		<Segment raised padded='very'>
			<EmptyState {...emptyState} />
		</Segment>
	) : (
		<Segment removePaddings='all' raised>
			<div className='actionitems-wrapper' id={randomItemsID}>
				{props.actionHeader || !isNaN(props.totalItems) ? (
					<ActionHeader
						{...props.actionHeader}
						sticky={!document.querySelector('html').classList.contains('mobile')}
						bottomBorder
						header={{
							...props.actionHeader?.header,
							labels: getHeaderLabels()
						}}
						size={props.size}
						scrollTo={`#${randomItemsID}`}
						onStickyChange={(active) => setStickyActive(active)}
						onSizeChange={(element) => setStickyHeight(element.offsetHeight)}
					/>
				) : null}
				{React.isValidElement(props.chart) ? (
					<>
						<Segment basic compressed removeMargins='all'>
							{props.chart}
						</Segment>
						<Divider removeMargins='all' />
					</>
				) : props.chart?.chart ? (
					<>
						<Segment basic compressed removeMargins='all'>
							{props.chart.chartInfo ? (
								<Grid stackable stretched>
									<Grid.Column width={9}>
										<Chart isAnimated isBoxed {...props.chart.chart} />
									</Grid.Column>
									<Grid.Column width={3}>
										<ChartInfo {...props.chart.chartInfo} />
									</Grid.Column>
								</Grid>
							) : (
								<Chart isAnimated isBoxed {...props.chart.chart} />
							)}
						</Segment>
						<Divider removeMargins='all' />
					</>
				) : null}
				{props.items.length > 0 && (props.select?.enableSelectAll || props.display?.buttons || props.sort) ? (
					<>
						<Segment
							className='actionitems-header'
							style={{ top: stickyHeight }}
							squared
							secondary
							basic
							compressed='very'
							removeMargins='all'
							removePaddings='vertical'
						>
							<Segment basic compressed='very' removeMargins='all'>
								<Grid compact verticalAlign='middle' columns={2}>
									{props.select?.enableSelectAll ? (
										<Grid.Column collapsing>
											<Checkbox
												name='select-all'
												label={<FormattedMessage id='component.action-items.selectAll' />}
												checked={
													props.select && Array.isArray(props.select.selectedItems)
														? props.items.length === props.select.selectedItems.length
															? true
															: false
														: undefined
												}
												indeterminate={
													props.select && Array.isArray(props.select.selectedItems)
														? props.select.selectedItems.length > 0 &&
														  props.items.length > props.select.selectedItems.length
															? true
															: false
														: undefined
												}
												value='all'
												disabled={props.totalRows == 0}
												size={props.size}
												onChange={handleSelect}
											/>
										</Grid.Column>
									) : null}
									{props.display.buttons ? (
										<Grid.Column collapsing>
											<Button.Group fitted buttons={props.display.buttons} size='tiny' />
										</Grid.Column>
									) : null}
									{props.sort ? (
										<Grid.Column collapsing>
											<Dropdown {...props.sort} size='tiny' />
										</Grid.Column>
									) : null}
								</Grid>
							</Segment>
						</Segment>
					</>
				) : null}

				<>
					{props.items.length > 0 ? (
						<Segment
							basic
							compressed
							removePaddings={props.display.type === 'items' ? 'all' : undefined}
							removeMargins='all'
							className={itemsWrapperClasses}
							loading={props.loading ? 'scrolling' : undefined}
						>
							{props.display.type === 'cards' ? (
								<CardGroup
									items={formatItems(props.items)}
									renderItem={props.renderItem}
									loading={props.loading}
									layout={props.layout}
									totalRows={props.totalItems}
									limitHeader={props.limitHeader}
									limitDescription={props.limitDescription}
									columns={props.columns}
									mobile={props.mobile}
									tablet={props.tablet}
									computer={props.computer}
									largescreen={props.largescreen}
									widescreen={props.widescreen}
									onSelect={props.select ? handleSelect : undefined}
								/>
							) : props.display.type === 'items' ? (
								<ItemGroup
									items={formatItems(props.items)}
									renderItem={props.renderItem}
									loading={props.loading}
									layout={props.layout}
									totalRows={props.totalItems}
									limitHeader={props.limitHeader}
									limitDescription={props.limitDescription}
									onSelect={props.select ? handleSelect : undefined}
								/>
							) : null}
							{showMore ? (
								<Segment
									basic
									compressed
									textAlign='center'
									removeMargins='all'
									compressed={props.display.type === 'cards'}
									removePaddings={props.display.type === 'items' ? 'top' : undefined}
									className='item-pagination-wrapper'
								>
									<Button
										text={
											<FormattedMessage
												id='component.action-table.showMore'
												values={{ min: nextMin, max: nextMax, total: totalItems }}
											/>
										}
										circular
										icon={{ name: 'fa-chevron-down', solid: true }}
										loading={props.paginate && props.paginate.loadingMore}
										onClick={handleLoadMore}
									/>
								</Segment>
							) : null}
						</Segment>
					) : (
						<Segment basic compressed removeMargins='all'>
							<Message
								info
								content={<FormattedMessage id={translations.noResults} />}
								icon='fa-info-circle'
							/>
						</Segment>
					)}
				</>
			</div>
		</Segment>
	);

	return actionItems;
});

// Documentation generation support
Component.displayName = 'ActionItems';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
