/**
 * @prettier
 * @flow
 */

import { Grid, Responsive } from 'liana-ui/components/';
import SuggestedItem from './src/SuggestedItem';

// prettier-ignore
type Props = {
	/** A suggested items can have clickable items. */
	items: Array<{
		link: string,
		type: String,
		header: string,
		subheader: String,
		content: React.Node
	}>,
	/** Amount of items to display on mobile. */
	mobileAmount?: number,
	/** Amount of items to display on tablet. */
	tabletAmount?: number,
	/** Amount of items to display on computer. */
	computerAmount?: number,
	/** Amount of items to display on largescreen. */
	largescreenAmount?: number,
	/** Amount of items to display on widescreen. */
	widescreenAmount?: number
};

const DEFAULTS = {
	mobileAmount: 1,
	tabletAmount: 2,
	computerAmount: 3,
	largescreenAmount: 4,
	widescreenAmount: 6
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const getColumns = (amount) => {
		let columns = [];
		if (props.items.length < amount) {
			amount = props.items.length;
		}
		if (props.items && Array.isArray(props.items)) {
			for (let i = 0; i < amount; i++) {
				columns.push(getColumn(props.items[i], i));
			}
		}
		return columns;
	};
	const getColumn = (item, index) => {
		return (
			<Grid.Column key={`column${index}`}>
				<SuggestedItem
					link={item.link}
					header={item.header}
					subheader={item.subheader}
					content={item.content}
					type={item.type}
				/>
			</Grid.Column>
		);
	};
	let menu = (
		<div className='suggesteditems-wrapper'>
			<Grid stretched columns='equal'>
				<Responsive minDevice='mobile' maxDevice='mobile'>
					{getColumns(props.mobileAmount)}
				</Responsive>
				<Responsive minDevice='tablet' maxDevice='tablet'>
					{getColumns(props.tabletAmount)}
				</Responsive>
				<Responsive minDevice='computer' maxDevice='computer'>
					{getColumns(props.computerAmount)}
				</Responsive>
				<Responsive minDevice='largescreen' maxDevice='largescreen'>
					{getColumns(props.largescreenAmount)}
				</Responsive>
				<Responsive minDevice='widescreen' maxDevice='widescreen'>
					{getColumns(props.widescreenAmount)}
				</Responsive>
			</Grid>
		</div>
	);

	return menu;
});

// Documentation generation support
Component.displayName = 'SuggestedItems';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
