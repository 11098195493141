/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

type Props = {
	/** Content as children. */
	children: string | React.Node,
	/** An element type to render as. */
	as: 'span' | 'div' | 'p' | 'sup',
	/** Text can be bold. */
	bold: boolean,
	/** Text can be bolder. */
	bolder: boolean,
	/** Text can be light. */
	light: boolean,
	/** Text can be lighter. */
	lighter: boolean,
	/** Text can be italic. */
	italic: boolean,
	/** Text can be underlined. */
	underline: boolean,
	/** Text can be different color. */
	color?: 'primary' | 'grey' | 'red' | 'orange' | 'yellow' | 'green' | 'blue',
	/** Text can be highlighted. */
	highlight: boolean | 'code',
	/** Text can vary in Size. */
	size?: 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge',
	/** Text can have different vertical align in container. */
	textAlign?: 'left' | 'center' | 'right',
	/** A plain text can be limited to maximum amount of charachrets followed by ellipsis. */
	limit?: number,
	/** A text can always keep all content on a single line. */
	singleLine?: boolean,
	/** Additional class names */
	className?: string,
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps
};

const DEFAULTS = {
	as: 'span',
	bold: false,
	bolder: false,
	light: false,
	lighter: false,
	italic: false,
	underline: false,
	highlight: false,
	singleLine: false
};

/** COMPONENT BASED ON: https://fomantic-ui.com/elements/text.html */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const createText = (props: Props) => {
		// Assign classes
		let classes = classNames(
			'ui text',
			{
				'text-highlighted': props.highlight,
				'text-code': props.highlight === 'code',
				'text-light': props.light,
				'text-lighter': props.lighter,
				'text-bold': props.bold,
				'text-bolder': props.bolder,
				'text-italic': props.italic,
				'text-underline': props.underline,
				'text-left': props.textAlign === 'left',
				'text-center': props.textAlign === 'center',
				'text-right': props.textAlign === 'right',
				nowrap: props.singleLine
			},
			props.color,
			props.size,
			props.className
		);

		// Limit text to maximum amount of charachters
		let children = props.children,
			popup = props.popup;
		if (props.limit && typeof children === 'string') {
			if (children.length > props.limit) {
				children = children.substring(0, props.limit) + '...';
				popup = { size: 'tiny', ...props.popup, text: props.children, delay: 500 };
			}
		}

		// Create text
		let text = undefined;
		switch (props.as) {
			case 'div':
				text = <div className={classes}>{children}</div>;
				break;
			case 'p':
				text = <p className={classes}>{children}</p>;
				break;
			case 'sup':
				text = <sup className={classes}>{children}</sup>;
				break;
			default:
				text = <span className={classes}>{children}</span>;
		}

		// Attach popup
		return Popup.attach(props.popup, text);
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createText(props)}</Responsive>
		) : (
			createText(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'Text';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
